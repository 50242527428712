<template>
  <tr>
    <Loader :active="loading" />
    <td>
      <span v-if="editing">
        <input type="text" class="form-control" v-model="product.name" />
      </span>
      <span v-else>{{ product.name }} </span>
    </td>
    <td>
      <span v-if="editing">
        <input type="checkbox" v-model="product.is_default" />
      </span>
      <span v-else
        ><i v-if="product.is_default" class="fas fa-check text-success"> </i>
        <i v-else class="fas fa-times text-danger"> </i>
      </span>
    </td>
    <td>
      <span v-if="editing">
        <select
          id="daysOfWeekSelect"
          class="form-control"
          v-model="days"
          multiple
        >
          <option value="Mon">Mon</option>
          <option value="Tue">Tue</option>
          <option value="Wed">Wed</option>
          <option value="Thu">Thu</option>
          <option value="Fri">Fri</option>
          <option value="Sat">Sat</option>
          <option value="Sun">Sun</option>
        </select>
        <div>
          <small class="text-secondary"
            >Hold down Ctrl / Cmd to select multiple.</small
          >
        </div>
      </span>
      <span v-else>{{ product.days_of_week_csv }} </span>
    </td>
    <td>
      <span v-if="editing">
        <input
          type="text"
          class="form-control"
          v-model="startTime"
          @blur="setStartTimeMins"
        />
        <small>(24 hour time)</small>
        <div>{{ startTimeReadable }}</div>
      </span>
      <span v-else>{{ readableHoursAndMins(product.start_time_mins) }} </span>
    </td>
    <td>
      <span v-if="editing">
        <input
          type="text"
          class="form-control"
          v-model="product.duration_mins"
        />
        ({{ asHours(product.duration_mins) }} hours)
      </span>
      <span v-else
        >{{ product.duration_mins }} ({{ asHours(product.duration_mins) }}
        hours)
        <div>
          <small class="text-secondary">Ends at {{ endTime }}</small>
        </div>
      </span>
    </td>
    <td>
      <span v-if="editing">
        <input type="text" class="form-control" v-model="product.hourly_rate" />
      </span>
      <span v-else>{{ product.hourly_rate }} /=</span>
    </td>
    <td>
      <span v-if="editing">
        <input
          type="text"
          class="form-control"
          v-model="product.min_duration_mins"
        />
      </span>
      <span v-else>
        <span v-if="product.min_duration_mins > 0">
          {{ product.min_duration_mins }}
        </span>
        <span v-else class="text-secondary fw-bold"> Not Set </span>
      </span>
    </td>
    <td>
      <span v-if="editing">
        <input
          type="text"
          class="form-control"
          v-model="product.offset_amount"
        />
        <div>
          <small class="text-secondary"
            >This amount is added to hourly_rate * hours</small
          >
        </div>
      </span>
      <span v-else>
        <span v-if="product.offset_amount == 0" class="text-secondary fw-bold">
          Not Set
        </span>
        <template v-else>
          {{ product.offset_amount }}
        </template>
      </span>
    </td>
    <td>
      <template v-if="!editing">
        <div class="row">
          <div class="col">
            <button class="btn btn-primary" @click.prevent="edit">
              <i class="fas fa-pen"> </i>
            </button>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <button class="mt-1 btn btn-danger" @click.prevent="deleteProduct">
              <i class="fas fa-trash"> </i>
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        <button class="btn btn-success" @click.prevent="save">
          <i class="fas fa-save"> </i>
        </button>
      </template>
    </td>
  </tr>
</template>

<script>
import api from "@/api";
import Loader from "vue-element-loading";
import BookingMixin from "@/mixins/Booking.js";
export default {
  props: ["product", "space"],
  components: {
    Loader,
  },
  data() {
    return {
      editing: false,
      loading: false,
      tStartTimeMins: null,
    };
  },
  computed: {
    endTime() {
      return BookingMixin.readableHoursAndMins(
        this.product.start_time_mins + this.product.duration_mins
      );
    },
    startTimeReadable() {
      if (!this.isValid24hrTimeString(this.startTime)) {
        return "Invalid time";
      }
      let st = this.startTime.replace(":", "");
      var hoursString;
      if (st.length == 3) {
        hoursString = st[0];
      } else {
        hoursString = st.substring(0, 2);
      }
      let hoursInt = parseInt(hoursString);
      let suffix = "am";
      if (hoursInt >= 12) {
        hoursInt = hoursInt == 12 ? hoursInt : hoursInt - 12;
        suffix = "pm";
      }
      let mins = st.substring(2, 4);
      return `${hoursInt}:${mins} ${suffix}`;
    },
    startTime: {
      get() {
        return this.minutesToHoursAndMinutes(this.product.start_time_mins);
      },
      set(val) {
        console.log("SET");
        if (!this.isValid24hrTimeString(val)) {
          return;
        }
        const mins = BookingMixin.hoursAndMinsToMins(val);
        if (mins != null) {
          // set the actual field after blur with setStartTimeMins
          this.tStartTimeMins = mins;
        }
      },
    },
    days: {
      get() {
        return this.product.days_of_week_csv.split(",");
      },
      set(val) {
        this.product.days_of_week_csv = val.join(",");
      },
    },
  },
  methods: {
    deleteProduct() {
      if (
        !confirm(
          "Are you sure you want to delete this product? \nIt will also delete existing bookings and recurrences that use it."
        )
      ) {
        return;
      }
      this.loading = true;
      const url = `products/${this.product.id}/`;
      return api.Util.axios.delete(url).then(() => {
        this.loading = false;
        window.location.reload();
      });
    },
    readableHoursAndMins(val) {
      return BookingMixin.readableHoursAndMins(val);
    },
    setStartTimeMins() {
      this.product.start_time_mins = this.tStartTimeMins;
    },
    isValid24hrTimeString(val) {
      let st = val.replace(":", "");
      if (st.length > 4) {
        return false;
      }
      return true;
    },
    asHours(mins) {
      const hours = parseFloat(mins) / 60;
      return hours.toFixed(2);
    },
    minutesToHoursAndMinutes(mins) {
      return BookingMixin.minutesToHoursAndMinutes(mins);
    },
    edit() {
      this.editing = true;
    },
    save() {
      this.editing = false;
      this.loading = true;
      if (this.product.id) {
        // this item already exists
        const url = `products/${this.product.id}/`;
        return api.Util.axios.patch(url, this.product).then(() => {
          this.loading = false;
        });
      } else {
        // we are creating this item
        return this.createProduct();
      }
    },
    async createProduct() {
      const url1 = `/products/`;
      const data = Object.assign({}, this.product);
      console.log(data);
      data["location"] = this.space.id;
      const res1 = await api.Util.axios.post(url1, this.product);
      this.loading = false;
      return res1.data;
    },
  },
  created() {
    if (this.product.isEditing) {
      this.editing = true;
    }
  },
};
</script>

<style scoped lang="scss">
form,
input,
textarea,
select {
  font-size: 14px;
}
#daysOfWeekSelect {
  min-height: 170px;
}
</style>
