<template>
  <div>
    <Loader :active="loading" />
    <h2>Products</h2>
    <div>
      <small>Total is calculated as offset_amount + (hourly_rate* hours)</small>
    </div>
    <table class="table table-responsive table-bordered mt-3">
      <thead>
        <th>Name</th>
        <th>Is Default</th>
        <th>Days Of Week</th>
        <th>Start Time</th>
        <th>Duration in Mins</th>
        <th>Hourly Rate</th>
        <th>Min Allowed Duration in Hours</th>
        <th>Offset Amount</th>
        <th>Actions</th>
      </thead>
      <tbody>
        <template v-for="product in products">
          <ProductRow :space="space" :key="product.id" :product="product" />
        </template>
        <tr>
          <td colspan="20" class="text-center">
            <button class="btn btn-primary" @click.prevent="addProduct">
              Add
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ProductRow from "./ProductRow.vue";
import Loader from "vue-element-loading";
export default {
  components: {
    ProductRow,
    Loader,
  },
  data() {
    return {
      loading: false,
      products: [],
    };
  },
  computed: {
    defaultProduct() {
      for (const product of this.products) {
        if (product.is_default) {
          return product;
        }
      }
      return null;
    },
    spaces() {
      return this.$store.state.Manager.spaces;
    },
    space() {
      const space = this.spaces.find((item) => {
        return item.slug == this.$route.params.slug;
      });
      if (space) {
        return space;
      }
      return null;
    },
  },
  methods: {
    addProduct() {
      this.products.push({
        id: null,
        name: "[New Product]",
        hourly_rate: 500,
        start_time_mins: 480,
        days_of_week_csv: "Mon,Tue,Wed,Thu,Fri",
        duration_mins: 540,
        min_duration_mins: -1,
        offset_amount: 0,
        location: this.space.id,
        isEditing: true,
      });
    },
    fetchProducts() {
      if (this.space) {
        this.products = this.space.products;
        return;
      }
    },
  },
  created() {
    this.fetchProducts();
  },
};
</script>
